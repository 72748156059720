<template>
	<div class="bg-white overflow-hidden shadow rounded-lg">
		<div class="bg-white shadow overflow-hidden  sm:rounded-lg">
			<div class="px-4 py-5 border-b border-gray-200 sm:px-6">
				<h3 class="text-lg leading-6 font-medium text-gray-900">
					Account Information
				</h3>
				<p class="mt-1 max-w-2xl text-sm leading-5 text-gray-500">
					Account Details
				</p>
				<p class="mt-1 text-xs leading-5 text-gray-500">
					You can add a subscription on the
					<router-link class="text-blue-500" :to="{ name: 'billing-settings' }">Billing</router-link>
					page
				</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'AccountSettings',
	computed: {
		...mapGetters('accounts', ['isOwner', 'account'])
	}
}
</script>

<style></style>
